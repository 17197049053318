<template>
  <div>
    <form
      autocomplete="off"
      @submit.prevent="submit"
    >
      <div class="o-row">
        <h4
          v-if="isTemp"
          style="padding: 0 8px 16px 8px"
        >
          On y est presque ! <br />
          Renseignez votre civilité, nom et date de naissance et choisissez
          votre pseudo We Are PlayStation pour finaliser votre inscription.
        </h4>
        <div class="o-col u-12 u-6@tb">
          <div class="c-form__group u-mb-3">
            <select
              id="civility"
              ref="civilityRef"
              v-model="formData.civility"
              name="civility"
              class="c-form__select"
              required
              :class="{ 'is-invalid': errors.civility }"
            >
              <option
                value
                disabled
                selected
              >
                Civilité
              </option>
              <option
                v-for="gender in referential?.choiceGender"
                :key="gender.value"
                :value="gender.value"
              >
                {{ gender.label }}
              </option>
            </select>
            <span
              v-if="errors.civility"
              class="c-form__feedback is-invalid"
            >
              {{ errors.civility }}
            </span>
          </div>
        </div>
        <div class="o-col u-12 u-6@tb">
          <div class="c-form__group u-mb-3">
            <input
              id="lastname"
              ref="lastnameRef"
              v-model="formData.lastname"
              type="text"
              name="lastname"
              class="c-form__control"
              placeholder="Nom*"
              required
              :class="{ 'is-invalid': errors.lastname }"
            />
            <span
              v-if="errors.lastname"
              class="c-form__feedback is-invalid"
            >
              {{ errors.lastname }}
            </span>
          </div>
        </div>
        <div class="o-col u-12 u-6@tb">
          <div class="c-form__group u-mb-3">
            <input
              id="firstname"
              ref="firstnameRef"
              v-model="formData.firstname"
              type="text"
              class="c-form__control"
              name="firstname"
              placeholder="Prénom*"
              required
              :class="{ 'is-invalid': errors.firstname }"
            />
            <span
              v-if="errors.firstname"
              class="c-form__feedback is-invalid"
            >
              {{ errors.firstname }}
            </span>
          </div>
        </div>
        <div class="o-col u-12 u-6@tb">
          <div class="c-form__group u-mb-3">
            <input
              id="email"
              ref="emailRef"
              v-model="formData.email"
              type="email"
              class="c-form__control"
              placeholder="Adresse e-mail*"
              required
              disabled
            />
          </div>
        </div>
        <div class="o-col u-12 u-6@tb">
          <div class="c-form__group u-mb-3">
            <input
              id="nickname"
              ref="bickNameRef"
              v-model="formData.nickname"
              type="text"
              class="c-form__control"
              name="nickname"
              placeholder="Pseudo*"
              required
              :class="{ 'is-invalid': errors.nickname }"
            />
            <span
              v-if="errors.nickname"
              class="c-form__feedback is-invalid"
            >
              {{ errors.nickname }}
            </span>
          </div>
        </div>
      </div>
      <div class="o-row u-ai-c u-mb-5">
        <div class="o-col u-12 u-6@tb">
          <div class="c-form__group u-mb-3">
            <input
              id="password"
              ref="passwordRef"
              type="password"
              class="c-form__control"
              value="********"
              required
              disabled
            />
          </div>
        </div>
        <div class="o-col u-auto">
          <span
            class="link u-mb-3"
            @click="openPasswordModal"
          >
            Modifier mon mot de passe
          </span>
        </div>
      </div>
      <div>
        <p class="u-mb-3">Ma date de naissance</p>
        <div class="o-row">
          <div class="o-col u-3">
            <div class="c-form__group">
              <select
                id="day"
                v-model="formData.birthData.day"
                name="day"
                class="c-form__select"
                required
                :class="{ 'is-invalid': errors.birthday }"
              >
                <option
                  value
                  disabled
                  selected
                >
                  JJ
                </option>
                <option
                  v-for="dayNumber in days"
                  :key="dayNumber"
                  :value="dayNumber"
                >
                  {{ dayNumber }}
                </option>
              </select>
            </div>
          </div>
          <div class="o-col u-3">
            <div class="c-form__group">
              <select
                id="month"
                v-model="formData.birthData.month"
                name="month"
                class="c-form__select"
                required
                :class="{ 'is-invalid': errors.birthday }"
              >
                <option
                  value
                  disabled
                  selected
                >
                  MM
                </option>
                <option
                  v-for="monthNumber in months"
                  :key="monthNumber"
                  :value="monthNumber"
                >
                  {{ monthNumber }}
                </option>
              </select>
            </div>
          </div>
          <div class="o-col u-6">
            <div class="c-form__group">
              <select
                id="year"
                v-model="formData.birthData.year"
                name="year"
                class="c-form__select"
                required
                :class="{ 'is-invalid': errors.birthday }"
              >
                <option
                  value
                  disabled
                  selected
                >
                  AAAA
                </option>
                <option
                  v-for="yearNumber in years"
                  :key="yearNumber"
                  :value="yearNumber"
                >
                  {{ yearNumber }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="errors.birthday"
            class="o-col u-12"
          >
            <span class="c-form__feedback is-invalid">
              {{ errors.birthday }}
            </span>
          </div>
        </div>
      </div>
      <hr />
      <div class="u-mb-4">
        <div class="news__group">
          <img
            src="~/assets/icons/news-alt.svg"
            alt="Newsletter icon"
            width="32"
            height="32"
          />
          <span class="news news--alt">
            Évaluez vos chances de gagner des bons d’achat !
          </span>
        </div>
        <div class="c-form__group">
          <div class="c-form__check u-ai-fs">
            <input
              id="wapNewsletter"
              v-model="formData.wapNewsletter"
              class="c-form__check-input"
              type="checkbox"
              name="wapNewsletter"
            />
            <label
              class="c-form__check-label"
              for="wapNewsletter"
            >
              J'accepte de recevoir chaque mois des emails m'informant du
              classement sur We Are PlayStation afin d'évaluer mes chances de
              remporter des bons d'achat.
            </label>
          </div>
        </div>
      </div>
      <div class="u-mb-4">
        <div class="news__group">
          <img
            src="~/assets/icons/news.svg"
            alt="News icon"
            width="32"
            height="32"
          />
          <span class="news">
            Soyez au courant de toute l'actualité PlayStation
          </span>
        </div>
        <div class="c-form__group">
          <div class="c-form__check u-ai-fs">
            <input
              id="sonyPlaystationNewsletter"
              v-model="formData.sonyPlaystationNewsletter"
              class="c-form__check-input"
              type="checkbox"
              name="sonyPlaystationNewsletter"
            />
            <label
              class="c-form__check-label"
              for="sonyPlaystationNewsletter"
            >
              J'accepte de recevoir des informations et des offres
              personnalisées par e-mail concernant PlayStation® et Sony de la
              part de Sony Interactive Entertainment Europe Limited.
            </label>
          </div>
        </div>
      </div>
      <hr />
      <p class="title">Compléter mon profil</p>
      <p class="text-small u-mb-3">
        Profil complété à {{ user?.profile_completion_percent }}%
      </p>
      <TheGauge
        :percent="user?.profile_completion_percent"
        background-color="#ededed"
        class="u-mb-4"
      />
      <p class="points">
        {{ referential?.points.user_profile_full }}
        {{ pluralize('point', referential?.points.user_profile_full) }}
        à gagner en complétant votre profil
      </p>
      <hr />
      <div class="u-mb-5">
        <p class="u-mb-3">Mon avatar</p>
        <div class="avatar">
          <label
            class="avatar__input"
            :class="{ 'is-active': !!formData.avatar }"
          >
            <input
              type="file"
              accept="image/png, image/jpeg"
              @change="onAvatarChange"
            />
            <div
              v-if="!formData.avatar"
              class="avatar__empty"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
              >
                <path
                  fill="#fff"
                  d="M8.5 20v-8.5H0v-3h8.5V0h3v8.5H20v3h-8.5V20z"
                />
              </svg>
            </div>
            <img
              v-else
              :src="formData.avatar"
              class="avatar__picture"
            />
          </label>
          <div class="avatar__infos">
            JPEG ou PNG 600x600px minimum.
            <br />
            2Mo max.
          </div>
        </div>
        <span
          v-if="errors.avatar"
          class="c-form__feedback is-invalid"
        >
          {{ errors.avatar }}
        </span>
      </div>
      <div class="u-mb-5">
        <p class="u-mb-3">Mon profil joueur</p>
        <div class="c-form__group u-mb-3">
          <input
            id="psnId"
            ref="psnIdRef"
            v-model="formData.psnId"
            type="text"
            class="c-form__control"
            name="psnId"
            placeholder="PSN ID (facultatif)"
            :class="{ 'is-invalid': errors.psnId }"
          />
          <span
            v-if="errors.psnId"
            class="c-form__feedback is-invalid"
          >
            {{ errors.psnId }}
          </span>
        </div>
      </div>
      <div class="u-mb-5">
        <p class="u-mb-3">Vous possédez</p>

        <div
          v-for="(platform, index) in referential?.platforms"
          :key="`p-${index}`"
        >
          <div class="c-form__choice u-mb-2">
            <input
              :id="`p-${index}`"
              v-model="formData.platforms"
              class="c-form__choice-input"
              type="checkbox"
              name="platform"
              :value="platform.id"
            />
            <label
              class="c-form__choice-label"
              :for="`p-${index}`"
            >
              <span>{{ platform.name }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="u-mb-5">
        <p class="u-mb-3">Quels services utilisez-vous ?</p>

        <div
          v-for="(service, index) in referential?.services"
          :key="`s-${index}`"
        >
          <div class="c-form__choice u-mb-2">
            <input
              :id="`s-${index}`"
              v-model="formData.services"
              class="c-form__choice-input"
              type="checkbox"
              name="service"
              :value="service.id"
            />
            <label
              class="c-form__choice-label"
              :for="`s-${index}`"
            >
              <span>{{ service.name }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="u-mb-5">
        <p class="u-mb-3">Votre adresse et numéro de téléphone</p>
        <p class="text-small u-mb-3">
          Renseignez ces informations pour vos participations aux concours et
          événements, nous ne vous les redemanderons plus !
          <br />
          <strong
            >Attention, seuls les résidents français peuvent participer.</strong
          >
        </p>
        <div class="c-form__group u-mb-3">
          <input
            id="address"
            ref="addressRef"
            v-model="formData.address"
            type="text"
            class="c-form__control"
            name="address"
            placeholder="Adresse"
            :class="{ 'is-invalid': errors.address }"
          />
          <span
            v-if="errors.address"
            class="c-form__feedback is-invalid"
          >
            {{ errors.address }}
          </span>
        </div>
        <div class="c-form__group u-mb-3">
          <input
            id="addressAdditional"
            ref="addressAdditionalRef"
            v-model="formData.addressAdditional"
            type="text"
            class="c-form__control"
            name="addressAdditional"
            placeholder="Complément d'adresse"
            :class="{ 'is-invalid': errors.addressAdditional }"
          />
          <span
            v-if="errors.addressAdditional"
            class="c-form__feedback is-invalid"
          >
            {{ errors.addressAdditional }}
          </span>
        </div>
        <div class="o-row">
          <div class="o-col u-12 u-6@tb">
            <div class="c-form__group u-mb-3">
              <input
                id="zipCode"
                ref="zipCodeRef"
                v-model="formData.zipCode"
                type="text"
                class="c-form__control"
                name="zipCode"
                placeholder="Code postal"
                :class="{ 'is-invalid': errors.zipCode }"
              />
              <span
                v-if="errors.zipCode"
                class="c-form__feedback is-invalid"
              >
                {{ errors.zipCode }}
              </span>
            </div>
          </div>
          <div class="o-col u-12 u-6@tb">
            <div class="c-form__group u-mb-3">
              <input
                id="city"
                ref="cityRef"
                v-model="formData.city"
                type="text"
                class="c-form__control"
                name="city"
                placeholder="Ville"
                :class="{ 'is-invalid': errors.city }"
              />
              <span
                v-if="errors.city"
                class="c-form__feedback is-invalid"
              >
                {{ errors.city }}
              </span>
            </div>
          </div>
          <div class="o-col u-12 u-6@tb">
            <div class="c-form__group u-mb-3">
              <input
                type="text"
                class="c-form__control"
                value="France"
                disabled
              />
            </div>
          </div>
          <div class="o-col u-12 u-6@tb">
            <div class="c-form__group u-mb-3">
              <input
                id="phone"
                ref="phoneRef"
                v-model="formData.phone"
                type="tel"
                class="c-form__control"
                name="phone"
                placeholder="Numéro de mobile"
                :class="{ 'is-invalid': errors.phone }"
              />
              <span
                v-if="errors.phone"
                class="c-form__feedback is-invalid"
              >
                {{ errors.phone }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="u-mb-5">
        <p class="text-small">
          En modifiant vos informations de profil vous confirmez avoir lu,
          compris et accepté les
          <NuxtLink
            to="/page/conditions-dutilisation"
            target="_blank"
            >conditions générales d'utilisation</NuxtLink
          >
          de We Are PlayStation. Vos informations seront stockées selon notre
          <NuxtLink
            to="/page/politique-de-confidentialite"
            target="_blank"
            >politique de confidentialité</NuxtLink
          >.
        </p>
      </div>
      <div
        v-if="errorMessage"
        class="text-small is-invalid u-mb-3"
      >
        {{ errorMessage }}
      </div>
      <div
        v-if="success"
        class="text-small u-mb-3"
      >
        {{ success }}
      </div>
      <div class="footer u-mb-5">
        <span
          class="link delete"
          @click="openDeleteModal"
          >Supprimer mon compte</span
        >
        <ButtonSimple
          type="submit"
          label="Enregister mes informations"
          :disabled="loading"
        />
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useVfm } from 'vue-final-modal'
import type { ValidationError } from 'yup'
import type { Endpoints } from '~/types/endpoints'
import { API, days, months, years } from '~/utils/constants'
import { updateProfileFormSchema } from '~/utils/validations'

const referentialStore = useReferentialStore()
const { referential } = storeToRefs(referentialStore)

const errorMessage = ref('')
const success = ref('')

const { user, isTemp } = useTempAuth()

const [year, month, day] = user.value?.birthday?.split('-') ?? ['', '', '']

interface Errors {
  civility?: string
  lastname?: string
  firstname?: string
  nickname?: string
  birthday?: string
  avatar?: string
  psnId?: string
  address?: string
  addressAdditional?: string
  zipCode?: string
  city?: string
  phone?: string
}

type ErrorKeys = keyof Errors

const formData = reactive({
  birthData: { day, month, year },
  birthday: '',
  civility: user.value?.civility ?? '',
  lastname: user.value?.lastname ?? '',
  firstname: user.value?.firstname ?? '',
  email: user.value?.email ?? '',
  nickname: user.value?.nickname ?? '',
  psnId: user.value?.psn_id ?? '',
  avatar: user.value?.avatar?.url ?? '',
  address: user.value?.address ?? '',
  addressAdditional: user.value?.address_additional ?? '',
  zipCode: user.value?.zip_code ?? '',
  city: user.value?.city ?? '',
  phone: user.value?.phone ?? '',
  wapNewsletter: user.value?.wap_newsletter ?? '',
  sonyPlaystationNewsletter: user.value?.sony_playstation_newsletter ?? '',
  services: user.value?.services?.map((s) => s.id) ?? [],
  platforms: user.value?.consoles?.map((c) => c.id) ?? [],
})

const loading = ref(false)
const errors = ref<Errors>({})
const vfm = useVfm()

const submit = async () => {
  loading.value = true
  errorMessage.value = ''
  success.value = ''

  formData.birthday = `${formData.birthData.year}-${formData.birthData.month}-${formData.birthData.day}`

  try {
    await updateProfileFormSchema.validate(formData, { abortEarly: false })
    errors.value = {}
  } catch (err) {
    const error = err as ValidationError

    const tempErrors = {} as Errors
    error.inner.forEach((innerError) => {
      if (innerError.path) {
        tempErrors[innerError.path as ErrorKeys] = innerError.message
      }
    })

    errors.value = tempErrors

    loading.value = false

    return
  }

  const formBody = {
    firstname: formData.firstname,
    lastname: formData.lastname,
    nickname: formData.nickname,
    psnId: formData.psnId,
    civility: formData.civility,
    birthday: { ...formData.birthData },
    services: formData.services,
    consoles: formData.platforms,
    address: formData.address,
    addressAdditional: formData.addressAdditional,
    zipCode: formData.zipCode,
    city: formData.city,
    phone: formData.phone,
    wapNewsletter: formData.wapNewsletter,
    sonyPlaystationNewsletter: formData.sonyPlaystationNewsletter,
    avatar: undefined as string | undefined,
  }

  if (formData.avatar.includes('data:')) {
    formBody.avatar = formData.avatar
  }

  const { error } = await useCustomFetch<Endpoints['PUT__UPDATE_PROFILE']>(
    API.PUT__UPDATE_PROFILE,
    {
      method: 'PUT',
      body: formBody,
    },
  )

  if (error.value) {
    errorMessage.value =
      'Une erreur est survenue, veuillez vérifier les champs du formulaire.'
    const { data, statusCode } = error.value

    if (statusCode === 400) {
      const { errors: responseErrors } = data

      const tempErrors = {} as Errors

      Object.keys(responseErrors).forEach((key) => {
        const errorKey = key as ErrorKeys
        tempErrors[errorKey] = responseErrors[errorKey].join(' ')
      })
      errors.value = tempErrors
    }
    loading.value = false

    return
  }

  const isTempUser = useIsTempUser().value
  if (isTempUser) {
    await finalizeUser()
    console.log('Profile updated')
  }

  success.value = 'Votre profil a bien été mis à jour.'

  setTimeout(() => {
    if (isTempUser) {
      return navigateTo('/')
    }

    vfm.closeAll()
  }, 500)

  loading.value = false
}

const onAvatarChange = (e: Event) => {
  const target = e.target as HTMLInputElement
  const files = target.files || (e as DragEvent).dataTransfer?.files

  if (!files?.length) {
    return
  }

  errors.value.avatar = ''

  const file = files[0]
  const reader = new FileReader()
  const image = new Image()

  if (file.type.includes('image/gif')) {
    errors.value.avatar = 'Les fichiers .gif ne sont pas autorisés'

    return
  }

  if (!(file.type.includes('image/png') || file.type.includes('image/jpeg'))) {
    errors.value.avatar = "Ce type de fichier n'est pas autorisé."

    return
  }

  if (file.size > 2 * 1000 ** 2) {
    errors.value.avatar = 'Cette image pèse trop lourd (2Mo max).'

    return
  }

  reader.addEventListener(
    'load',
    async () => {
      // convert image file to base64 string
      const { result } = reader

      if (typeof result !== 'string') return

      let preview = result

      if (!result.includes('image/png;base64')) {
        image.src = preview
      }

      const { data, error } = await useFetch<{
        optimized?: string
        statusCode?: number
      }>(API.POST_POLISH, {
        method: 'POST',
        body: { base: reader.result },
      })

      if (error.value) {
        image.src = preview

        return logError(
          'from File Preview',
          'There was a problem posting base64 image: ',
          error,
        )
      }

      const rawData = unref(data)

      if (rawData?.optimized) {
        preview = rawData.optimized
      }

      image.src = preview
    },
    false,
  )

  image.addEventListener('load', () => {
    if (image.width < 600 || image.height < 600) {
      errors.value.avatar = 'Votre image doit faire 600x600px minimum'

      return
    }

    formData.avatar = image.src
  })

  if (file) {
    reader.readAsDataURL(file)
  }
}

const emit = defineEmits<{
  openUpdatePasswordModal: []
  deleteAccountModal: []
  close: []
}>()

const openPasswordModal = () => {
  emit('openUpdatePasswordModal')
}

const openDeleteModal = () => {
  emit('deleteAccountModal')
}
</script>

<style lang="scss" scoped>
hr {
  margin: 2.5rem 0;
  border-bottom: 0;
  opacity: 0.15;
}

.is-invalid {
  color: #ea5151;
}

.text-small {
  font-size: 1.2rem;
}

.news__group {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}

.news {
  margin-left: 1rem;
  color: $blue;
  font-family: 'SST Condensed';
  font-size: 1.6rem;
  font-weight: 500;

  &--alt {
    color: $orange;
  }
}

.title {
  margin-bottom: 3.5rem;
  font-size: 2.6rem;
  line-height: 1.15;

  @media (min-width: 992px) {
    margin-bottom: 5rem;
    font-size: 3.2rem;
  }
}

.points {
  color: $orange;
  font-family: 'SST Condensed';
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}

.link {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(#000, 0.19);
    transition: background 0.35s ease-in-out;
  }

  &:hover::after {
    background-color: transparent;
  }
}

.delete {
  color: #ff5050;

  &::after {
    background-color: #ff5050;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 991px) {
    :first-child:not(:last-child) {
      order: 2;
      margin-top: 3rem;
    }
  }
}

.avatar {
  display: flex;
  align-items: center;

  &__input {
    position: relative;
    width: 9rem;
    height: 9rem;
    overflow: hidden;
    background-color: #e3e3e3;
    border: 1px solid #d0d0d0;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #d0d0d0;
    }

    &.is-active {
      &:hover::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.4);
        background-image: url('assets/icons/pencil.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 2rem;
      }
    }

    input {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__picture {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__infos {
    margin-left: 2rem;
    font-size: 1.2rem;
    line-height: 1.2;
  }
}

// Overrides
.c-form__check {
  margin-left: 4.2rem;
}

.c-form__check-label {
  line-height: 1.25;
}
</style>
